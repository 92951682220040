<script setup lang="ts">
    interface UspBarProps {
        items?: Array<{ icon: string; content: string; toLink: string }>;
    }

    const props = defineProps<UspBarProps>();

    const currentColumn = ref(0);
    const isTablet = ref(false);
    useMediaQuery([
        {
            query: defaultQueries.max.tablet,
            ref: isTablet,
        },
    ]);

    const fadeTransition = (index: number) => {
        const classes = [];
        if (index === currentColumn.value) {
            classes.push('max-lg:opacity-100 max-lg:z-10');
        } else if (index > currentColumn.value + 1) {
            classes.push('max-lg:opacity-0 max-lg:delay-100');
        } else {
            classes.push('max-lg:opacity-0');
        }
        return classes.join(' ');
    };

    watch(
        [isTablet, currentColumn],
        async () => {
            if (isTablet.value) {
                await wait(4000);
                if (currentColumn.value >= (props.items?.length || 0) - 1) {
                    currentColumn.value = 0;
                } else {
                    currentColumn.value = currentColumn.value + 1;
                }
            }
        },
        { deep: true, immediate: true },
    );
</script>

<template>
    <div
        v-if="items && items.length > 0"
        class="mol-ups-bar bg-woom-white-smoke py-6 md:py-8">
        <atm-grid>
            <div
                v-for="(item, index) in items"
                :id="'usp-row-' + index"
                :key="'usp-row-' + index"
                :data-index="'usp-row-' + index"
                :class="[
                    'relative col-span-full max-lg:col-start-1 max-lg:row-start-1 max-lg:opacity-0 max-lg:transition-opacity max-lg:duration-300 max-lg:ease-out',
                    {
                        'lg:col-span-2': items.length > 4,
                        'lg:col-span-3': items.length === 4,
                        'lg:col-span-4': items.length === 3,
                        'lg:col-span-6': items.length === 2,
                        'lg:col-span-12': items.length === 1,
                    },
                    fadeTransition(index),
                ]">
                <nuxt-link
                    :to="item.toLink"
                    :class="['flex h-full items-center gap-3 text-xs max-lg:flex-col', { 'transition-colors hover:text-woom-red': item.toLink }]">
                    <component
                        :is="item.icon"
                        class="h-7 w-7 shrink-0 fill-current" />
                    {{ item.content }}
                </nuxt-link>
            </div>
        </atm-grid>
    </div>
</template>
